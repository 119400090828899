
.iconclass{
  
    font-size: 58px;
   }
   .about {
    width: 100%;
    max-width: 900px;
    overflow:hidden;
  }
  .about img {
    width: 100%;
    max-width: 900px;
    padding: 0;
    margin: 0;
  }
  .about video {
    width: 100%;
    max-width: 900px;
  }
   
  .about table {
    border-collapse: collapse;
    margin: 0 auto;
    text-align: center;
  }
  .about table td,
  table th {
    border: 1px solid #cad9ea;
    color: #666;
    height: 30px;
  }
  .about table thead th {
    background-color: #cce8eb;
    width: 100px;
  }
  .about table tr:nth-child(odd) {
    background: #fff;
  }
  .about table tr:nth-child(even) {
    background: #f5fafa;
  }
  .sj{
    color: #8c8c8c;//#bfbfbf     #8c8c8c
  }
  .syh{ font-weight: bolder; font-size: 1.2em;}
  .syb{  font-size: 1em;  }